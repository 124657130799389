import React, { Children } from 'react'
import styled from '@emotion/styled'
import { Thumbnails, Slides, useCarousel } from '../carousel'

const Container = styled.div``

const CardThumbnail = styled.div`
  padding: ${p => `${p.theme.space[4]} 0`};
  flex: 1;

  :before {
    content: '';
    display: block;
    border: ${p => `2px solid ${p.theme.colors.black[0]}`};
    opacity: ${p => !p.selected && 0.3};
  }
`

const CardThumbnails = styled(Thumbnails)`
  margin-bottom: ${p => p.theme.space[6]};

  ${CardThumbnail} + ${CardThumbnail} {
    margin-left: ${p => p.theme.space[4]};
  }
`

const CardSlides = styled(Slides)`
  overflow: ${p => p.overflow};
`

const CardSlide = styled.div`
  min-width: 100%;
  padding-right: ${p => p.theme.space[3]};
`

export const CardCarousel = ({
  children,
  storageKey,
  className,
  thumbnails = true,
  Slide = CardSlide,
  options,
}) => {
  const { slidesRef, selectedIndex, select } = useCarousel(storageKey, options)
  const overflow = thumbnails ? 'hidden' : 'visible'

  return (
    <Container className={className}>
      {thumbnails && (
        <CardThumbnails>
          {Children.map(children, (_, index) => (
            <CardThumbnail
              selected={index === selectedIndex}
              onClick={() => select(index)}
              key={index}
            />
          ))}
        </CardThumbnails>
      )}
      <CardSlides ref={slidesRef} overflow={overflow}>
        {Children.map(children, (child, index) => (
          <Slide key={index}>{child}</Slide>
        ))}
      </CardSlides>
    </Container>
  )
}
