import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import { Hr as BaseHr } from '../rules'

const Container = styled.div`
  display: flex;
  flex-flow: column;
`

const Title = styled.h3`
  margin: 0;
  font-size: ${p => p.theme.fontSizes[4]};
  line-height: 1.5;

  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[5]};
  }
`

const Description = styled.p`
  margin: 0;
`

const Hr = styled(BaseHr)`
  margin: ${p => `${p.theme.space[6]} 0`};
`

export const Card = ({ title, children, className }) => (
  <Container className={className}>
    <Title>{title}</Title>
    <Hr />
    <Description>{children}</Description>
  </Container>
)
