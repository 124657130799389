import React from 'react'
import { css } from '@emotion/react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { breakpoints, mediaQueries } from '../../theme'

const ArtDirectedImage = ({ desktop, mobile, ...rest }) => {
  const imgDesktop = getImage(desktop)
  const imgMobile = getImage(mobile)

  const imagesWithArtDirection = withArtDirection(imgMobile, [
    {
      media: `(min-width: ${breakpoints.md})`,
      image: imgDesktop,
    },
  ])

  return (
    <GatsbyImage
      image={imagesWithArtDirection}
      loading="lazy"
      css={css`
        container-type: inline-size;

        & img {
          height: ${100 * (imgMobile.height / imgMobile.width)}cqw;
          ${mediaQueries.md} {
            height: ${100 * (imgDesktop.height / imgDesktop.width)}cqw;
          }
        }
      `}
      {...rest}
    />
  )
}

export default ArtDirectedImage
