import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${p => `9rem ${p.theme.space[8]} ${p.theme.space[8]}`};
  text-align: center;
  background: ${p => `url(${p.image}) no-repeat`};
  background-position: top ${p => p.theme.space[22]} center;
  background-size: ${p => `${p.theme.space[8]} ${p.theme.space[8]}`};

  &.inactive {
    color: ${p => p.theme.colors.gray[11]};
    position: relative;

    &:before {
      content: 'Soon';
      position: absolute;
      top: ${p => p.theme.space[6]};
      right: ${p => p.theme.space[6]};
      padding: 0 ${p => p.theme.space[2]};
      font-size: 0.75rem;
      line-height: 2;
      color: rgba(0, 0, 0, 0.2);
      background-color: ${p => p.theme.colors.gray[2]};
      border-radius: ${p => p.theme.space[1]};
    }
  }
`

const Title = styled.h3`
  margin: 0;
  margin-bottom: ${p => p.theme.space[3]};
  font-size: ${p => p.theme.fontSizes[4]};
  line-height: 1.4;
`

const Description = styled.p`
  max-width: 13.75rem;
  margin: 0;
  font-size: ${p => p.theme.fontSizes[1]};
  line-height: 1.7;
`

export const ImageCard = ({ image, title, description, className }) => {
  return (
    <Container image={image} className={className}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  )
}
