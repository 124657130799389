import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${p => p.theme.space[12]};

  ${mediaQueries.md} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: ${p => `${p.theme.space[16]} ${p.theme.space[14]}`};
  }

  ${mediaQueries.lg} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`
